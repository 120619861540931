<template>
  <section
    id="services"
    class="overflow-hidden"
  >
    <v-row no-gutters>
      <v-col
        class=" text-center pa-13"
        cols="12"
        md="12"
      >
        <base-subheading>
          Dienstleistungen
        </base-subheading>

        <base-text class="mb-5">
          Wir stehen für Qualität, Zuverlässigkeit und Termintreue
        </base-text>
      </v-col>
    </v-row>
    <v-container>
      <v-spacer />
      <v-row class="justify-space-around">
        <v-col
          cols="12"
          md="4"
        >
          <v-card>
            <v-img

              height="250px"
              :src="require('@/assets/project3.jpeg')"
            >
              <v-container
                class="fill-height"
                fluid
              >
                <v-row
                  class="fill-height"
                  no-gutters
                >
                  <v-col
                    class="flexbox"
                    cols="4"
                  />
                </v-row>
              </v-container>
            </v-img>
            <v-card-text>
              <div>
                <p class="text-center text-h6">
                  Bau
                </p>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-card>
            <v-img
              height="250px"
              :src="require('@/assets/Umbau.jpg')"
            >
              <v-container
                class="fill-height"
                fluid
              >
                <v-row class="fill-height">
                  <v-col
                    class="align-end flexbox"
                    cols="4"
                  />
                </v-row>
              </v-container>
            </v-img>
            <v-card-text>
              <div>
                <p class="text-center text-h6">
                  Umbau
                </p>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-card>
            <v-img

              height="250px"
              :src="require('@/assets/Maler.jpg')"
            >
              <v-container
                class="fill-height"
                fluid
              >
                <v-row class="fill-height">
                  <v-col
                    class="align-end flexbox"
                    cols="3"
                  />
                </v-row>
              </v-container>
            </v-img>
            <v-card-text>
              <div>
                <p class="text-center text-h6">
                  Maler- & Gipserarbeiten
                </p>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <br>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-card>
            <v-img
              height="250px"
              :src="require('@/assets/sanitaer.jpg')"
            >
              <v-container
                class="fill-height"
                fluid
              >
                <v-row class="fill-height">
                  <v-col
                    class="align-end flexbox"
                    cols="4"
                  />
                </v-row>
              </v-container>
            </v-img>
            <v-card-text>
              <div>
                <p class="text-center text-h6">
                  Sanitär- & Heizungsinstallationen
                </p>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-card>
            <v-img
              height="250px"
              :src="require('@/assets/boden.jpg')"
            >
              <v-container
                class="fill-height"
                fluid
              >
                <v-row class="fill-height">
                  <v-col
                    class="align-end flexbox"
                    cols="4"
                  />
                </v-row>
              </v-container>
            </v-img>
            <v-card-text>
              <div>
                <p class="text-center text-h6">
                  Keramik und Bodenbeläge
                </p>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-card>
            <v-img
              height="250px"
              :src="require('@/assets/reinigung.jpg')"
            >
              <v-container
                class="fill-height"
                fluid
              >
                <v-row class="fill-height">
                  <v-col
                    class="align-end flexbox"
                    cols="4"
                  />
                </v-row>
              </v-container>
            </v-img>
            <v-card-text>
              <div>
                <p class="text-center text-h6">
                  Reinigung
                </p>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <br>
  </section>
</template>

<script>
  export default {
    name: 'Services',

    data: () => ({
      services: [
        {
          name: 'Research',
          icon: 'mdi-clipboard-text-outline',
          blurb: 'Curabitur et nisi semper,<br> pellent e sque ',
        },
        {
          name: 'Design',
          icon: 'mdi-pencil-outline',
          blurb: 'Curabitur et nisi semper, <br>pellent.',
        },
        {
          name: 'Development',
          icon: 'mdi-settings-outline',
          blurb: 'Curabitur et nisi semper, <br>pellent.',
        },
        {
          name: 'Support',
          icon: 'mdi-account',
          blurb: 'Curabitur et nisi semper, <br>pellent.',
        },
      ],
    }),
  }
</script>
